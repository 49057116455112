<template>
  <div class=" bbr-admin--settings">
    <user-whitelisting />

    <v-divider />
  </div>
</template>

<script>
import UserWhitelisting from '../components/UserWhitelisting'

export default {
  name: 'AdminSettingsPage',

  components: { UserWhitelisting },
}
</script>
