<template>
  <v-row class="bbr-admin--user-whitelisting">
    <v-col cols="12" sm="3">
      <h3>User Whitelisting</h3>
      <p class="subtitle-2">
        User whitelisting for toggling between Release Candidates and Master
        releases.
      </p>
    </v-col>

    <v-col cols="12" sm="7">
      <div v-if="loading" class="whitelist-loader">
        <v-skeleton-loader type="list-item-two-line" class="px-0" />
      </div>

      <div v-else>
        <div ref="whitelistChips">
          <v-chip
            class="mr-2 mb-2 whitelist-chip"
            v-for="(user, index) in whitelistedUsers"
            :key="index"
            :class="[`whitelist-chip-${index}`]"
            @click:close="removeUser(user)"
            close
          >
            {{ user.email }}
          </v-chip>

          <v-chip v-if="addNewEmail" class="mr-2 mb-2 px-10">
            <v-progress-circular indeterminate color="primary" :size="20" />
          </v-chip>

          <v-chip
            v-if="!addEmail && hasWhitelistedUsers"
            class="mr-2 mb-2 px-3 pr-6"
            color="primary"
            @click="addEmail = true"
            dark
          >
            <v-icon class="mr-2"> {{ icons.add }} </v-icon>
            <span class="font-weight-bold"> Add New User </span>
          </v-chip>
        </div>

        <div class="d-flex align-center mt-2">
          <user-whitelisting-search-input
            v-if="addEmail || !hasWhitelistedUsers"
            class="mb-5"
            :has-whitelisted-users="hasWhitelistedUsers"
            :whitelisted-users="whitelistedUsers"
            :add-email="addEmail"
            @selected="selectedUser"
            return-object
            hide-details
            clearable
            outlined
            rounded
            dense
          >
            <template v-slot:cancel>
              <span
                class="click font-weight-bold ma-1 primary--text"
                color="success"
                @click="addEmail = false"
                text
              >
                Cancel
              </span>
            </template>
          </user-whitelisting-search-input>

          <v-spacer />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import UserWhitelistingSearchInput from '@/components/elements/users/UserWhitelistingSearchInput'

import { mapActions, mapMutations, mapState } from 'vuex'
import { mdiPlus } from '@mdi/js'

export default {
  name: 'UserWhitelisting',

  components: { UserWhitelistingSearchInput },

  data() {
    return {
      loading: false,
      addEmail: false,
      addNewEmail: false,
      removingEmail: false,
      icons: {
        add: mdiPlus,
      },
    }
  },

  computed: {
    ...mapState({
      whitelistedUsers: (state) => state.admin.whitelistedUsers,
    }),

    hasWhitelistedUsers() {
      return !!this.whitelistedUsers.length
    },
  },

  created() {
    this.fetchWhitelistedUsers()
  },

  methods: {
    ...mapActions({
      getWhitelistedUsers: 'admin/getWhitelistedUsers',
      addNewWhitelistedUser: 'admin/addNewWhitelistedUser',
      removeWhitelistedUser: 'admin/removeWhitelistedUser',
    }),

    ...mapMutations({
      addUserToWhitelist: 'admin/addUserToWhitelist',
      removeUserFromWhitelist: 'admin/removeUserFromWhitelist',
    }),

    async removeUser(user) {
      if (this.removingEmail) return

      this.removingEmail = true

      await this.removeUserFromWhitelist(user)
      await this.removeWhitelistedUser(user.id)

      this.removingEmail = false
    },

    async fetchWhitelistedUsers() {
      this.loading = true

      await this.getWhitelistedUsers()
      await this.$sleep()

      this.loading = false
    },

    async selectedUser(user) {
      this.addNewEmail = true

      await this.addNewWhitelistedUser(user.email)
      await this.$sleep(800)

      this.addNewEmail = false

      this.whitelistedUsers.push(user)
    },
  },
}
</script>

<style lang="scss">
.click {
  cursor: pointer;
}

.whitelist-loader {
  .v-skeleton-loader__list-item-two-line {
    padding: 0 !important;
  }
}
</style>
