<template>
  <v-autocomplete
    v-model="whitelistedUser"
    v-bind="$attrs"
    :search-input.sync="search"
    :items="searchedUsers"
    :loading="loading"
    @change="newUserSelected"
    placeholder="Search for user email"
    item-text="email"
    item-value="id"
  >
    <template v-if="hasWhitelistedUsers || search" v-slot:append>
      <slot name="cancel" />
    </template>

    <template v-slot:item="{ item }" disabled>
      <v-list-item-content>
        <v-list-item-title class="primary--text">
          {{ item.full_name }}
        </v-list-item-title>
        <v-list-item-subtitle class="text-no-wrap" style="width:20px">
          {{ item.email }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import User from '@/models/User'

import { filter, includes, debounce } from 'lodash'

export default {
  name: 'SearchUserWhitelisting',

  props: {
    addEmail: Boolean,
    whitelistedUsers: Array,
    hasWhitelistedUsers: Boolean,
  },

  data() {
    return {
      search: null,
      loading: false,
      searchedUsers: [],
      whitelistedUser: null,
      whitelists: this.whitelistedUsers,
    }
  },

  methods: {
    searchForusers: debounce(async function() {
      this.loading = true

      const query = User.page(1).where('search', this.search)

      const { data } = await query.params({ limit: 500 }).get()

      this.$sleep(300)

      this.loading = false

      let filteredData = filter(data, (user) => {
        const _exists = includes(this.whitelistedUsers, user.email)

        if (!_exists) {
          return user
        }
      })

      this.searchedUsers = filteredData
    }, 600),

    newUserSelected(user) {
      if (!user) return

      this.search = null
      this.searchedUsers = []
      this.whitelistedUser = null

      this.$emit('selected', user)
    },
  },

  watch: {
    search(value) {
      if (!value) return

      this.searchForusers()
    },
  },
}
</script>
